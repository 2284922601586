


import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import {  setToken } from './service/mftc-api';
import Footer from './components/Footer';
import ButtonApi from './components/ButtonApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons'
import { MfctContext } from "./context";
import Loader from "./components/Loader";



function Profile() {

  const navigate = useNavigate();
  const { project, getProject,authUser,setAuthUser} = useContext (MfctContext);
const [loading,setLoading] = useState(false)
const [windowOpener,setWindowOpener]=useState()

useEffect(()=>{
  setWindowOpener( window.opener);
},[])

const close=()=>{
  window.opener.postMessage({authUser,token: localStorage.getItem('token')},"*");
}
const logout=async ()=>{
  setToken(null);
  setAuthUser(null);

}
useEffect(()=>{
if( !authUser) navigate("/")
},[authUser])

  useEffect(() => {
    getProject();
  }, [])


 

  return (
    <>{
      loading && <Loader/>    }
 <div className="fixed text-white font-bold p-0 px-0 w-full  flex justify-start items-center  bg-zinc-800 z-20">
  <a   href='/'><FontAwesomeIcon  icon={fa.faArrowLeft} className='text-white text-xl mr-1  px-9 py-8 hidden' /></a>
       
      </div>
      { authUser &&  <div className=" flex justify-center items-center flex-col  min-h-screen bg-zinc-800  w-screen text-white   ">
      <div  className='text-3xl   italic font-bold border  bg-zinc-200 w-40 h-40 rounded-xl flex flex-col  justify-center items-center m-4 text-zinc-700'   >    <span>App</span><span><span>Is</span><span  className='text-red-700 ' >You</span></span></div>

        <div className=" w-full  ">

          
            <div className="  flex flex-col  justify-center items-center">


              
                <span className='mb-6 text-xl'>VOUS ÊTES CONNECTÉ </span> 

              <div className='flex flex-col justify-center items-center w-full'>
               {  authUser.email }   </div>


      {windowOpener &&         <button className={ "bg-white text-zinc-900 w-1/2 p-2 my-3 rounded-md mx-2 font-bold text-xs "  }   onClick={close}   > Ok </button> } 
               
              
                <div onClick={logout} className='underline italic  p-4 text-sm'> Déconnexion    </div>
                
            </div>
            

        </div>

      </div> 
}
      <Footer />
    </>
  );
}

export default Profile;
