

import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  useMemo, useState } from 'react';
import { projectInit, setToken, getAuthUser } from '../service/mftc-api';
import ButtonApi from './ButtonApi';

function    Footer ({  })  {

  return   <footer className='w-full h-72 bg-zinc-900  p-10 text-white text-lg flex flex-col justify-center items-center'>
  
  <a href="/"   className=' text-white  italic font-bold'  style={ {} }  >    <span>App</span><span>Is</span><span  className='text-red-700 ' >You</span><span className='text-red-700  mb-4' >!</span></a>
  <span   className=' text-white text-xs italic font-bold'>© 2023 APP IS YOU</span> 
  </footer>
} 

export default Footer;
