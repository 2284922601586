


export const setToken = (token, authUser) => {
    if (token) {
        localStorage.setItem('token', token)
        localStorage.setItem('authUser', JSON.stringify(authUser))
    }
    else {
        localStorage.removeItem('token');
        localStorage.removeItem('authUser');
    }
}
export const getAuthUser = () => {

    var result = localStorage.getItem('authUser')
    if (result) {
        try {
            return JSON.parse(result)
        }
        catch (err) {
            //console.log(err)
        }
    } else return null;

}


export const projectInit = async (url_server, project_name,create=false) => {

    var headers = {};
    var token = localStorage.getItem('token')
    if (token)     headers['Authorization'] = "Bearer " + token;
    if (process.env.REACT_APP_MFTC_APP) headers["Mftc-Application"] = process.env.REACT_APP_MFTC_APP
     

    var parts =  window.location.href.split(":30");
    if(  parts.length>1) {

         
        var _parts =url_server.split(":30");
         _parts[0]=parts[0]
        url_server =_parts.join(":30");
    }

    //process.env.REACT_APP_MFTC_SERVER_URL 

    try{
    const response = await fetch(url_server + `${project_name}/init/` + (create? "1":"0"), {
        method: 'get',
        headers: { ...headers }
    });


    const project = await response.json();





    if( project )
    { project.api = async (endpoint, params) => {
        if ( !project.endpoints[endpoint] ) {
            console.log("No endpoint like this', endpoint: " + endpoint)
            return;
        }
        var url = url_server + project_name + project.endpoints[endpoint].url;
        if (params && params.args) {
            Object.keys(params.args).forEach(key => {
                url = url.split(":" + key).join(params.args[key]);
            })
        }
        var response;
        var headers = {};
        var token = localStorage.getItem('token')
        if (token)       headers['Authorization'] = "Bearer " + token;
        
        if (process.env.REACT_APP_MFTC_APP) headers["Mftc-Application"] = process.env.REACT_APP_MFTC_APP
        

        switch (project.endpoints[endpoint].method) {

            case "get":
                response = await fetch(url, {
                    method: 'GET',

                    headers
                });

                break;
            case "post":
                response = await fetch(url,
                    {
                        method: 'POST',
                        headers: { ...headers, "Content-Type": "application/json" },
                        body: JSON.stringify(params.body)
                    })

                break;
            case "postform":
                const formData = new FormData();
                if (params.form) {
                    Object.keys(params.form).forEach(key => {
                        formData.append(key, params.form[key]);
                    })
                }

                response = await fetch(url,
                    {
                        method: 'POST',
                        body: formData,
                        headers: {
                            ...headers,
                            //     "Content-Type": "multipart/form-data"
                        }
                    }
                );

                break;
            case "put":
                response = await fetch(url,
                    {
                        method: 'PUT',
                        headers: {
                            ...headers,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(params.body)
                    });
                break;
            case "delete":
                response = await fetch(url,
                    {
                        method: 'DELETE',

                        headers: {
                            ...headers,
                            //     "Content-Type": "multipart/form-data"
                        }
                    }
                );
                break;

        }
        if (response) {
            try {
                const result = await response.json();

                return result;
            } catch (err) {
                console.log("JSON ERROR", err, response);

                return null
            }
        }
        return {}
    }


    project.urlImage = (urlImage)=>{
if( !urlImage) return null
if(  urlImage.substring(0, 4)!=="img_") return urlImage;
//console.log(   url_server.split("/api")[0]   +"/user_img/"+ urlImage);
return     url_server.split("/api")[0]   +"/user_img/"+ urlImage


    }

    }

    console.log("projectInit", project);
    return project;
    }
    catch(e)
{
  console.log("error project connection");

  const project = {api:()=>{return {}}}
  return project; 
}
}



