
import './App.css';

import {
  BrowserRouter,
  useNavigate,
  Switch,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";
import Auth from './Auth';
import Profile from './Profile';
import { useEffect, useMemo, useState } from 'react';
import { MfctContext } from './context';
import { getAuthUser, projectInit } from './service/mftc-api';
import Loader from "./components/Loader";

function App() {

  const [authUser,setAuthUser] = useState(getAuthUser())
  const [project,setProject]=useState()
  
  async function getProject() {
  
    console.log("getProject", process.env.REACT_APP_MFTC_SERVER_URL, process.env.REACT_APP_PROJECT_NAME)
    const p = await projectInit(process.env.REACT_APP_MFTC_SERVER_URL, process.env.REACT_APP_PROJECT_NAME)
    console.log('project', p);
    setProject(p);
  
  }

  const Init =  () => {

    const navigate = useNavigate();
    useEffect(()=>   
    navigate(authUser ? "/profile":"/signin")
    ,[])

  return <Loader />
  
  }
  const value = useMemo(()=>{
  
   return {
    authUser,
    setAuthUser,
    project,
    getProject
   } 
  },[authUser,project])
  
  useEffect (() => {
    getProject();

  }, [authUser])
  
  return (

    <MfctContext.Provider value={value}>
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Init />} />
        <Route path="/signin" element={<Auth  mode={"signin"}  />} />
        <Route path="/signup" element={<Auth  mode={"signup"} />} />
        <Route path="/profile" element={<Profile    />} />
   
      </Routes>
    </BrowserRouter>
    </MfctContext.Provider>
  );
}

export default App;
